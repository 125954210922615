<template>
  <!-- begin::post-card-list -->
  <ul class="post-card-list">
    <li class="post-card-item"  v-for="(item, idx) in items" :key="idx">
      <div class="post-layer-card">

        <router-link :to="`interview/${item.interviewId}/view`" class="card-link"><span class="screen-out">자세히 보기</span></router-link>
        <div class="card-inner">
<!--          <div class="interview">-->
<!--            <small class="interview-title">interview</small>-->
<!--            <p class="interview-num">{{ item.interviewNo }}</p>-->
<!--          </div>-->
          <div class="card-content">
<!--            <p class="subtitle">{{ item.interviewSmlTit }}</p>-->
<!--            <h4 class="title">{{ item.interviewTit }}</h4>-->
            <!--<p class="view">
              <i class="icon-view">main/view_icon.svg</i>
              <span class="text">{{ item.inqCnt }}</span>-->
            </p>
          </div>
        </div>
        <div class="card-image">
          <img :src="item.rprsImgPtUrl" alt="">
        </div>
      </div>
    </li>
  </ul>
  <!-- end::post-card-list -->
</template>

<script>
export default {
  name: 'InterviewCard',
  props: {
    items: Array,
    viewLink: String
  }
};
</script>
